<template>
  <v-data-table
      :headers="headers"
      :items="getSortedLeads"
      :item-class="itemRowBackground"
      :options="tableOptions"
      :loading="loading"
      @item-expanded="loadConversations"
      :expanded.sync="expanded"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      multi-sort


  >
    <template #top>
      <div class="d-flex">
        <v-checkbox v-model="hideProcessed" label='Hide Processed'>
        </v-checkbox>
        <v-checkbox v-model="hideSent" label='Hide Sent'>
        </v-checkbox>
        <v-checkbox v-model="zoneOnly" label='Zone Only'>
        </v-checkbox>
        <v-checkbox v-model="usOnly" label='US Only'>
        </v-checkbox>
        <v-checkbox v-model="noErrors" label='No Errors'>
        </v-checkbox>
        <v-checkbox v-model="brandedOnly" label='Branded Only'>
        </v-checkbox>
      </div>
    </template>
    <template #[`item.approve`]="{ item }">
      <v-btn
          fab
          x-small
          @click="approveItem(item)">
        <v-icon>
          mdi-check
        </v-icon>
      </v-btn>

      <backorder-menu-small
          :domain="item.domain"
      />
    </template>
    <template #[`item.delete`]="{ item }">
      <v-btn
          fab
          x-small
          @click="removeItem(item)">
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
    <template #[`item.info`]="{ item }">
      <domain-card :domain-name="item.domain"/>
    </template>

    <template #[`item.price`]="{ item }">
      <evaluation-menu :domain-name="item.domain"
                       :suggested-price="item.price"/>
    </template>

    <template #[`item.domain`]="{item}">
      <v-container fluid>

        <v-row>
          <v-col>
            <span v-if="item.sibling.startsWith(item.particle)" class="text-button">{{item.particle}} </span>
            <domain-menu :name="item.domain"/>
            <span v-if="!item.sibling.startsWith(item.particle)" class="text-button"> {{item.particle}}</span>
            <br>
            <sibling-menu :name="item.sibling"/>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <conversation-card
            :domain-name="item.domain"
            :reload="true"
            class="px-0 mx-0"
        />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import DomainMenu from "@/components/DomainMenu.vue";
import SiblingMenu from "@/components/SiblingMenu.vue";
import EvaluationMenu from "@/components/EvaluationMenu.vue";
import DomainCard from "@/components/domains/DomainCard.vue";
import BackorderMenuSmall from "@/components/BackorderMenuSmall.vue";
import ConversationCard from "@/components/conversations/ConversationCard.vue";

export default {
  components: {
    ConversationCard,
    BackorderMenuSmall,
    DomainCard,
    EvaluationMenu,
    DomainMenu,
    SiblingMenu
  },
  data() {
    return {
      dialog: false,
      tableOptions: {
        itemsPerPage: 20
      },
      editedIndex: -1,
      editedItem: {
        text: "",
        timesUsed: 0,
      },
      defaultItem: {
        text: "",
        timesUsed: 0,
      },
      expanded: [],

      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      headers: [

        {text: "Info", value: "info", sortable: false},
        {text: "Error", value: "error"},
        {text: "Country", value: "country"},
        {text: "Title", value: "title"},
        {text: "Domain", value: "domain"},
        {text: "Price", value: "price"},
        {text: "Domain POS", value: "domainPos"},
        {text: "Sibling POS", value: "siblingPos"},
        {text: "Remove", value: "delete"},
        {text: "Email", value: "email"},
        {text: "Approve", value: "approve"},


      ],
    };
  },
  computed: {
    ...mapGetters("leads", ["getSortedLeads"]),
    ...mapState("leads", ["loading", "hideProcessed"]),

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    hideProcessed: {
      get() {
        return this.$store.state.leads.hideProcessed;
      },
      set(val) {
        this.$store.commit("leads/SET_HIDE_PROCESSED", val);
      },
    },
    hideSent: {
      get() {
        return this.$store.state.leads.hideSent;
      },
      set(val) {
        this.$store.commit("leads/SET_HIDE_SENT", val);
      },
    },
    zoneOnly: {
      get() {
        return this.$store.state.leads.zoneOnly;
      },
      set(val) {
        this.$store.commit("leads/SET_ZONE_ONLY", val);
      },
    },
    usOnly: {
      get() {
        return this.$store.state.leads.usOnly;
      },
      set(val) {
        this.$store.commit("leads/SET_US_ONLY", val);
      },
    },
    noErrors: {
      get() {
        return this.$store.state.leads.noErrors;
      },
      set(val) {
        this.$store.commit("leads/SET_NO_ERRORS", val);
      },
    },
    brandedOnly: {
      get() {
        return this.$store.state.leads.brandedOnly;
      },
      set(val) {
        this.$store.commit("leads/SET_BRANDED_ONLY", val);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  // mounted() {
  //   const searchFilter = {
  //     dropDate: new Date()
  //   }
  //   this.$store.dispatch(`${this.$route.name}/search`, searchFilter)
  // },


  methods: {
    ...mapActions({
      updateLead: 'leads/updateLead',
      updateLeadSending: 'leads/updateLeadSending',
      loadConversationsByDomainNameAction: "conversations/loadConversationsByDomainNameAction"
    }),

    async loadConversations(event) {
      console.log("Loading conversations for item", event.item.domain, event.value);

      if (event.value) {
        await this.loadConversationsByDomainNameAction(event.item.domain);
      }
    },

    removeItem(item) {
      console.log("Removing item", item);
      item.approvedToSend = false
      item.reviewDate = new Date()
      this.updateLeadSending(item)
    },
    approveItem(item) {
      item.approvedToSend = true
      item.reviewDate = new Date()
      this.updateLeadSending(item)
    },

    itemRowBackground(item) {
      if (item.approvedToSend != null) {
        if (item.approvedToSend) return "approved";
        else return "not-approved"
      }
      return "blank"
    },
  },
};
</script>

<style>
.approved {
  background-color: rgb(95, 211, 100)
}

.not-approved {
  background-color: rgb(253, 2, 2)
}

.blank {
  background-color: white;
}


</style>
